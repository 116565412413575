/* === 2.1. HEADER === */

.header {
  position: relative;
  z-index: 99;
  left: 0;
  right: 0;
  background: rgb(255 255 255);
  border-bottom: 1px solid #b2b2b230;

  .page-navigation {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .site-menu {
    height: 45px;
    padding: 0;

    a {
      font-size: 35px;
      color: #212121;

      &:hover {
        color: black;
      }
    }
  }

  .navbar-brand {
    padding: 15px 0px;
    display: inline-block;

    #logo {
      display: block;

      @media screen and (max-width: 468px) {
        max-width: 160px;
      }
    }

    #logo_sticky {
      display: none;
      @media screen and (max-width: 468px) {
        max-width: 160px;
      }
    }

    img {
      transition: opacity 0.3s, visibiliy 0.3s;
    }

    &:hover .navbar-brand-hover {
      width: 100%;
    }
  }

  .custom-menu li {
    margin: 10px 0;

    a {
      padding-left: 17px;
      display: block;
      font-size: 18px;
      line-height: 1.5em;
      font-weight: 500;
      color: rgb(0, 0, 0);
      text-decoration: none;

      &:hover {
        color: var(--color-primary);
      }

      i {
        float: left;
        margin-left: -17px;
        margin-top: 2px;
      }
    }
  }

  .megamenu .theme-heading-1 {
    color: #000;
    margin-bottom: 20px;
    font-size: 23px;
    line-height: 1.3;
    font-weight: 400;
  }

  .header-module {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    & ~ .header-module {
      margin-left: 5px;
    }

    a {
      text-decoration: none;
    }

    p {
      margin-bottom: 0;
    }

  }

  .wishlist-wrap, .contact-wrap {
    position: relative;
    display: inline-block;
    margin: 0 20px 0 0;
    font-size: 25px;
    line-height: 24px;
    width: 24px;
    height: 24px;
    text-align: center;
    cursor: pointer;

    a {

    }

    /*.wishlist-btn:after {
      font-family: inherit;
      content: attr(data-count);
      font-size: 10px;
      text-align: center;
      position: absolute;
      top: -4px;
      right: -8px;
      min-width: 16px;
      height: 16px;
      line-height: 16px;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      background-color: #f76b6a;
      color: #fff
    }*/

    i {
      color: black
    }
  }

  .social-icon i {
    color: black
  }

  .mini-cart-wrap {
    display: inline-block;
    width: 30px;

    .mini-cart {
      display: flex;
      align-items: center;

      .mini-cart-icon {
        font-size: 25px;
        margin-right: 20px;
        position: relative;

        i {
          color: black
        }

        /*&:after {
          font-family: inherit;
          content: attr(data-count);
          font-size: 10px;
          text-align: center;
          position: absolute;
          top: -4px;
          right: -8px;
          min-width: 16px;
          height: 16px;
          line-height: 18px;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          border-radius: 100%;
          background: #88c74a;
          color: #fff
        }*/
      }
    }

    .widget-shopping-cart-content {
      position: absolute;
      top: 150%;
      left: -220px;
      z-index: 99999;
      visibility: hidden;
      overflow: auto;
      padding: 0px;
      width: 370px;
      background-color: #fff;
      opacity: 0;
      -moz-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.15);
      box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.15);
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -ms-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;

      .cart-list {
        padding: 25px;
        margin: 0;
        max-height: 373px;
        overflow: auto;
        border-bottom: 1px solid #f1eeea;
      }

      .cart-list li {
        padding: 20px 0;
        border-bottom: 1px solid #f1eeea;
        display: inline-block;
        width: 100%;
      }

      .cart-list li:first-child {
        padding-top: 0;
      }

      .cart-list li:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      .cart-list li.empty {
        color: #222;
        font-weight: 400;
      }

      .cart-list li a {
        font-weight: 500;
        display: block;
        color: #88c74a;
        font-weight: 500;
      }

      .cart-list li .remove {
        position: relative;
        float: right;
        width: 20px;
        height: 20px;
        text-align: center;
        font-size: 28px;
        line-height: 20px;
        border-radius: 0;
        color: #5e5a54;
        font-weight: 400;
        font-family: Helvetica, Arial, sans-serif;
      }

      .cart-list li img {
        float: left;
        margin: 0 20px 0 0;
        width: 80px;
        height: 80px;
        border: 2px solid #f1eeea;
      }

      .cart-list .quantity {
        height: auto;
        display: block;
      }

      .total {
        margin: 0;
        padding: 30px;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
        border-bottom: 1px solid #f1eeea;
        position: relative;
        text-align: center;
      }

      .total .amount {
        position: absolute;
        right: 30px;
        top: 29px;
        color: #88c74a;
        text-transform: capitalize;
      }

      .buttons {
        margin: 0;
        padding: 30px;
        text-align: center;
      }

      .buttons a {
      }
    }

    &.open .widget-shopping-cart-content {
      visibility: visible;
      opacity: 1;
    }

  }

  .mainbar {
    padding: 5px 30px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;

    @media screen and (max-width: 468px) {
      padding: 20px 10px;

      .contact-wrap, .wishlist-wrap {
        display: none;
      }
    }
  }

  .inner {
    /*display: flex;
    align-items: center;
    height: 30px;*/
  }

  &:hover {
    .circle-one {
      transform: translateX(18px);
    }

    .circle-three {
      transform: translateX(-18px);
    }
  }

  .page-open-mobile-menu {
    margin: 0 0 0 20px;

    .circle {
      height: 5px;
      width: 5px;
      background: white;
      border-radius: 100%;
      margin: 0 2px;
    }
  }
}

#page_index {
  .header {
    position: fixed;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .6)), to(rgba(38, 38, 38, .1)));
    background: -o-linear-gradient(top, rgba(0, 0, 0, .6) 0, rgba(38, 38, 38, .1) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, .6) 0, rgba(38, 38, 38, .1) 100%);
    border-bottom: 0;
    -webkit-backdrop-filter: blur(2px) saturate(120%);
    backdrop-filter: blur(2px) saturate(120%);

    .topbar {
      border-bottom-color: #8a8a8a30 !important;

      span, .btn {
        color: white;
      }

      .header-module .module-trigger {
        color: inherit;
      }
    }

    .contact-wrap i,
    .social-icon i,
    .wishlist-wrap i,
    .mini-cart-wrap .mini-cart .mini-cart-icon i{
      color: #fff;
    }

    .sm > li > a {
      color: white;

      &:hover {
        color: white;
        border-color: white;
      }
    }

    .mainbar .site-menu a {
      color: inherit;
    }

    .page-open-mobile-menu .circle {
      background: white;
    }

    #logo {
      filter: invert(1);
    }
  }
}

.headroom {
  transition: transform .25s ease-in-out;
  will-change: transform
}

.headroom--pinned {
  transform: translateY(0)
}

.headroom--unpinned {
  transform: translateY(-100%)
}


.headroom.headroom--unpinned, .headroom.headroom--not-top {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, .07);

  .mainbar, topbar {
    /*-webkit-transform: translate3d(0,-91px,0);
    transform: translate3d(0,-91px,0);
    padding: 10px 30px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 8px rgba(0, 0, 0, .07);*/
    /*-webkit-backdrop-filter: blur(20px) saturate(180%);
    backdrop-filter: blur(20px) saturate(180%);*/
    /*position: fixed !important;
    top: 0;
    left: 0;
    right: 0;*/
  }
}

.headroom.headroom--not-top.headroom--pinned {
  padding: 10px 30px;
  background: rgba(255, 255, 255, 0.9) !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .07);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  backdrop-filter: blur(20px) saturate(180%);
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;

  .sm > li > a {
    color: #191919!important;
    &:hover, &.active {
      border-bottom: 1px solid #191919!important;
    }
  }

  .topbar {
    display: none;
  }

  .sm > li > a:hover {
    color: var(--color-primary);
  }

  #logo {
    display: none;
  }

  #logo_sticky {
    display: block;
  }

  i, .topbar span, .topbar .btn {
    color: black !important;
  }

  .mainbar .site-menu a {
    color: inherit;
  }

  .page-open-mobile-menu .circle {
    background: black;
  }

}

.megamenu-opened header {
  background: rgba(255, 255, 255, 1)!important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .07)!important;;

  .headroom.headroom--not-top.headroom--pinned {
    .mainbar {
      /*position: fixed !important;
      top: 0;
      left: 0;
      right: 0;*/
      /*-webkit-backdrop-filter: blur(20px) saturate(180%);
      backdrop-filter: blur(20px) saturate(180%);*/

    }
  }

  .sm > li > a {
    color: #191919!important;
    &:hover {
      color: var(--color-primary);
    }
  }

  .smpls_image_logo {
    display: block !important;
  }

  .smpls_image_logo_invert {
    display: none !important;
  }

  .mainbar i, .header-module i, .topbar .btn {
    color: black !important;
  }

  .mainbar .site-menu a {
    color: inherit;
  }

  .page-open-mobile-menu .circle {
    background: black;
  }

}
