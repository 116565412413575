.menu-canvas-panel {
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: rgba(0, 0, 0, .7);
  visibility: hidden;
  opacity: 0;
  cursor: pointer;

  > .inner {
	position: relative;
	height: 100%;
	max-width: 430px;
	text-align: left;
	cursor: default;
	background: #fff;
	width: 100%;
	transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
	display: flex;
	flex-direction: column;
	float: left;
	transform: translateX(-100%);
  }

  &.has-image {
	&:before {
	  content: '';
	  width: 100%;
	  height: 100%;
	  position: absolute;
	  background-color: rgba(255, 255, 255, 0.85);
	  z-index: 1;
	}
  }

  &.open {
	visibility: visible;
	opacity: 1;

	> .inner {
	  transform: translateX(0);
	}
  }

  .widget-panel-header {
	z-index: 10;
	position: relative;
	padding: 25px 65px;
	width: 100%;

	@media (max-width: $breakpoint-sm) {
	  padding: 30px;
	}

	.close-canvas-panel {
	  overflow-y: auto;
	  font-size: 45px;
	  color: silver;
	  position: relative;

	  &:hover {
		//color: @dark;
	  }
	}
  }

  .widget-panel-body {
	overflow-y: auto;
	padding: 65px;
	width: 100%;
	flex: 1 0 0%;

	@media (max-width: $breakpoint-sm) {
	  padding: 30px;
	  max-height: calc(100vh - 240px);
	}
  }

  @media (min-width: $breakpoint-sm) {
	.widget-panel-body::-webkit-scrollbar {

	}
  }

  .sidebar-language {
	margin-top: 60px;

	.current {
	  display: none;
	}

	ul {
	  margin: 0;
	  padding: 0;
	}

	li {
	  list-style: none;
	  margin-bottom: 0;
	  display: inline-block;
	  text-transform: uppercase;
	  padding: 0 12px;
	  font-size: 14px;

	  &:first-child {
		padding-left: 0;
	  }

	  &:last-child {
		padding-right: 0;
	  }

	  a {
		font-weight: 500;

		&:hover {
		  color: #000;
		}
	  }

	  &.actived {
		a {
		  color: #000;
		}
	  }
	}
  }

  .sidebar-text {
	color: black;
	font-size: 16px;
	white-space: pre-line;
	margin-top: 15px;
  }

  .widget-panel-bottom {
	margin-bottom: 60px;
	margin-top: 100px;
  }

  .social-links-widget {
	a {
	  color: #ccc;
	  padding: 0 20px;

	  &:first-child {
		padding-left: 0;
	  }

	  &:last-child {
		padding-right: 0;
	  }

	  &:hover {
		color: var(--color-primary);
	  }
	}
  }

  .menu {
	margin: 0;
	padding: 0;

	li {
	  padding: 0;
	  list-style: none;
	  margin-bottom: 0;

	  a {
		font-size: 22px;
		line-height: 1;
		font-weight: 500;
		letter-spacing: .36px;
		text-decoration: none;
		padding-top: 19px;
		padding-bottom: 19px;
		padding-left: 0;
		padding-right: 0;
		font-size: 18px;
		line-height: 1;
		font-weight: 500;
		letter-spacing: .36px;
		display: block;
		border-top: 1px solid #ffffff26;
		&:hover {
		  color: #000;
		}
	  }

	  &:first-child a {
		border: 0;
	  }

	  &.current-menu-parent > a,
	  &.current-menu-item > a,
	  &.current-menu-ancestor > a,
	  &.active > a,
	  &:hover > a {
		color: #ffb8b8;
	  }
	}

	.sub-menu {
	  display: none;
	  padding-left: 0;
	  padding-top: 15px;
	  padding-bottom: 5px;

	  .sub-menu {
		padding-bottom: 0;
		padding-left: 20px;
		padding-top: 5px;
	  }

	  li {
		padding: 3px 0;

		a {
		  font-size: 18px;
		  font-weight: 400;
		}

		&:last-child {
		  padding-bottom: 0;
		}

		li {
		  a {
			font-size: 16px;
		  }
		}
	  }
	}
  }

  .widget-panel-footer {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 50px;
	background-color: white;
  }
}

.off-canvas-layer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  cursor: pointer;
  display: none;
  background-color: rgba(0, 0, 0, 0.2);
}

.open-canvas-panel {
  /*.site {
	transform: translate3d(575px, 0, 0);
  }*/

  .off-canvas-layer {
	display: block;
  }
}

.open-cart-canvas-panel {

  .site {
	transform: translate3d(-675px, 0, 0);
  }
  .off-canvas-layer {
	display: block;
  }
}

.cart-canvas-panel {
  width: 675px;
  max-width: 100%;
  background-color: white;
  position: fixed;
  right: -675px;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 99;
  opacity: 0;
  @include box-shadow(5px 1px 70px #00000061);
  border-left: 1px solid #e4e4e4;
  @extend %NormalTransition;

  &.has-image {
	&:before {
	  content: '';
	  width: 100%;
	  height: 100%;
	  position: absolute;
	  background-color: rgba(255, 255, 255, 0.85);
	  z-index: 1;
	}
  }

  &.open {
	transform: translate3d(-675px, 0, 0);
	opacity: 1;
  }

  .widget-panel-content {
	display: flex;
	flex-direction: column;
	padding: 35px;
	flex-flow: column;
	height: 100%;

	@media (max-width: $breakpoint-sm) {
	  padding: 30px;
	}

  }

  .widget-panel-header {
	z-index: 10;
	text-align: center;

	.title-page {
	  font-size: 42px;
	  font-weight: 200;

	  @media (max-width: $breakpoint-sm) {
		font-size: 32px;
	  }

	}
  }

  .widget-panel-body {
	overflow-x: hidden;
	margin: 35px 0;
	overflow-y: scroll;
	max-height: calc(100vh - 330px);

	@media (max-width: $breakpoint-sm) {
	  max-height: calc(100vh - 240px);
	}

	.empty-wrapper {
	  padding: 90px;

	  @media (max-width: $breakpoint-sm) {
		padding: 0px;
	  }
	}
  }

  .widget-panel-body::-webkit-scrollbar {
	//display: none;
  }

  .close-canvas-panel {
	font-size: 45px;
	color: silver;
	position: absolute;
	right: 30px;
	top: 20px;

	&:hover {
	  color: black;
	}
  }

  table.shop_table td.product-price {
	font-size: 15px;
  }

}
